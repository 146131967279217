<template lang="">
  <div
    style="background-color: #E6E6E6; padding: 2px 4px"
    class="mb-25 rounded-8px"
  >
    <div
      class="d-flex cursor-pointer w-100"
      @click.stop.prevent="visibleCollapse = !visibleCollapse"
    >
      <div class="d-flex-between flex-fill">
        <!-- <div
          :style="`${['1G', 'F1'].includes(tripItem.source) ? `position: relative; box-sizing: border-box; overflow: hidden` : ''} `"
        >
          <div
            :class="`${tripItem.source === '1G' ? `d-flex-center logo-1G ${tripItem.contentSource ? `logo-${tripItem.contentSource}` : ''} ml-2` : (tripItem.source === 'F1' ? `d-flex-center logo-F1 ml-2` : '')}`"
          >
            <IAmLogoAirline
              :airline="tripItem.airline"
              :height="40"
            />
          </div>
        </div> -->

        <div>
          <div :style="`${['1G', 'F1', 'AK', 'TH', '1A'].includes(tripItem.source) ? `position: relative; box-sizing: border-box; overflow: hidden; width: 100%;` : ''} `">
            <div :class="`d-flex-center ml-1 ml-sm-2 ${resolveBadgeSource(tripItem.source, tripItem?.contentSource)}`">
              <IAmLogoAirline
                :airline="tripItem?.airline?.split(' | ')[0]"
                :height="40"
              />
            </div>
          </div>
        </div>

        <div
          v-if="isVisiblePriceFareCol"
          class="d-flex-center gap-1"
        >
          <span>Giá vé:</span>
          <span class="font-weight-bolder font-medium-3">{{ formatCurrency(priceCombinationOption.fareOpts[0].priceFareCol) }}</span>
        </div>

        <div
          v-if="isVisibleTotalFareCol"
          class="d-flex-center gap-1"
        >
          <span>Tổng:</span>
          <span class="fw-800 font-medium-4 text-danger">{{ formatCurrency(priceCombinationOption.fareOpts[0].priceTotalCol) }}</span>
        </div>
      </div>

      <div class="d-flex gap-1 px-1 py-25">
        <BButton
          variant="flat-warning"
          class="rounded-circle button-remove-focus"
          style="padding: 1px"
          @click.stop.prevent="handleSelectCombinationTrip(tripItem)"
        >
          <feather-icon
            v-if="isChecked"
            icon="CheckCircleIcon"
            :size="['xs'].includes(appBreakPoint) ? '22' : '28'"
            class="text-warning p-25"
          />
          <feather-icon
            v-else
            icon="CircleIcon"
            :size="['xs'].includes(appBreakPoint) ? '22' : '28'"
            class="p-25 text-secondary"
          />
        </BButton>

        <BButton
          :class="`${visibleCollapse ? null : 'collapsed'} rounded-circle p-50`"
          variant="flat-dark"
          :aria-expanded="visibleCollapse ? 'true' : 'false'"
        >
          <feather-icon
            :class="{ 'rotate-180': !visibleCollapse, 'rotate-transition': true }"
            icon="ChevronUpIcon"
            size="16"
          />
        </BButton>
      </div>
    </div>
    <!-- ANCHOR gia chi tiet -->
    <BCollapse
      :id="tripItem.id"
      :visible="visibleCollapse"
      role="tabpanel"
    >
      <TableDetailPrice
        :price-list="priceCombinationOption"
        :source="tripItem.source"
      />
    </BCollapse>
  </div>
</template>
<script>
import {
  BCollapse, BButton,
} from 'bootstrap-vue'
import { computed, ref, toRefs } from '@vue/composition-api'
import cloneDeep from 'lodash/cloneDeep'

import store from '@/store'

import {
  formatCurrency,
} from '@core/utils/filter'

import { combinateFareOptions } from '@flightv2/useBookingHandle'

export default {
  components: {
    BCollapse,
    BButton,
    IAmLogoAirline: () => import('@/components/IAmLogoAirline.vue'),
    TableDetailPrice: () => import('./TableDetailPrice.vue'),
  },
  props: {
    tripItem: {
      type: Object,
      required: true,
    },
    isChecked: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const visibleCollapse = ref(false)

    const tripItem = toRefs(props).tripItem
    function handleSelectCombinationTrip(trip) {
      if (props.isChecked) {
        store.dispatch('app-flight-v2/setCombinationSelectedTrip', null)
        return
      }
      const modifyTrip = cloneDeep(trip)
      delete modifyTrip.fareOptions
      modifyTrip.journeys = modifyTrip.journeys.filter(j => modifyTrip.bestCombinationFare.journeyIds.includes(j.journeyId))
      store.dispatch('app-flight-v2/setCombinationSelectedTrip', modifyTrip)
      this.$parent.$emit('clear')
    }

    const getShowPrice = computed(() => store.getters['app-flight-v2/getShowPrice'])
    const getShowPriceAfterDiscount = computed(() => store.getters['app-flight-v2/getShowPriceAfterDiscount'])
    const getCustomFeeServiceDataToModify = computed(() => store.getters['app-flight-v2/getCustomFeeServiceDataToModify'])
    const getFeeServiceInSelectedProfile = computed(() => store.getters['app-flight-v2/getFeeServiceInSelectedProfile'](false, tripItem.value.source))

    // Visible các cột
    const isVisiblePriceFareCol = computed(() => getShowPrice.value !== 'HIDE_FARE')
    // const isVisibleFeeCol = computed(() => !['HIDE_FARE', 'TICKET_FARE'].includes(getShowPrice.value))
    const isVisibleTotalFareCol = computed(() => !['HIDE_FARE', 'TICKET_FARE'].includes(getShowPrice.value))

    function resolveDetailPrice(fareOpt) {
      // getAgencyFee
      const {
        hideFee, agencyFee,
        hideFeeParent, agencyFeeParent,
        totalFeeAdult, totalFeeChild, totalFeeInfant,
        fareAdultAfterDiscount, fareAdultAfterDiscountAndPromotion,
        fareChildAfterDiscount, fareChildAfterDiscountAndPromotion,
        fareInfantAfterDiscount, fareInfantAfterDiscountAndPromotion,
      } = fareOpt

      const modifiedServiceFee = {
        adultAmount: null,
        childAmount: null,
        infantAmount: null,
      }
      // Nếu có custom pdv
      if (getCustomFeeServiceDataToModify.value?.adultAmount !== null || getCustomFeeServiceDataToModify.value?.childAmount !== null || getCustomFeeServiceDataToModify.value?.infantAmount !== null) {
        modifiedServiceFee.adultAmount = getCustomFeeServiceDataToModify.value?.adultAmount * fareOpt.amountSegmentToMultipleFeeService
        modifiedServiceFee.childAmount = getCustomFeeServiceDataToModify.value?.childAmount * fareOpt.amountSegmentToMultipleFeeService
        modifiedServiceFee.infantAmount = getCustomFeeServiceDataToModify.value?.infantAmount * fareOpt.amountSegmentToMultipleFeeService
      } else if (getFeeServiceInSelectedProfile.value) {
        // nếu chọn profile pdv
        const isSegmentFeeType = getFeeServiceInSelectedProfile.value?.feeType === 'FLIGHT_SEGMENT'
        const amountSegmentToMultipleFeeService = isSegmentFeeType ? tripItem.value?.journeys.reduce((a, c) => a + c.segments.length, 0) : 1
        modifiedServiceFee.adultAmount = getFeeServiceInSelectedProfile.value?.adultAmount * amountSegmentToMultipleFeeService
        modifiedServiceFee.childAmount = getFeeServiceInSelectedProfile.value?.childAmount * amountSegmentToMultipleFeeService
        modifiedServiceFee.infantAmount = getFeeServiceInSelectedProfile.value?.infantAmount * amountSegmentToMultipleFeeService
      }

      // NOTE: priceFareCol = tiền cột Fare, priceFeeCol = tiền cột phí, !! INF ko tính phí xuất vé
      // Adult
      const priceAdultServiceFee = modifiedServiceFee.adultAmount !== null ? modifiedServiceFee.adultAmount - fareOpt.serviceFeeAdult : 0
      const priceFareColAdult = getShowPriceAfterDiscount.value ? fareAdultAfterDiscountAndPromotion : fareAdultAfterDiscount
      let priceFeeColAdult = totalFeeAdult + priceAdultServiceFee
      // Child
      const priceChildServiceFee = modifiedServiceFee.childAmount !== null ? modifiedServiceFee.childAmount - fareOpt.serviceFeeChild : 0
      const priceFareColChild = getShowPriceAfterDiscount.value ? fareChildAfterDiscountAndPromotion : fareChildAfterDiscount
      let priceFeeColChild = totalFeeChild + priceChildServiceFee
      // Infant
      const priceInfantServiceFee = modifiedServiceFee.infantAmount !== null ? modifiedServiceFee.infantAmount - fareOpt.serviceFeeInfant : 0
      const priceFareColInfant = getShowPriceAfterDiscount.value ? fareInfantAfterDiscountAndPromotion : fareInfantAfterDiscount
      let priceFeeColInfant = totalFeeInfant + priceInfantServiceFee

      if (getShowPrice.value === 'NET_FARE') {
        priceFeeColAdult = fareOpt.taxAdult + fareOpt.surchargeAdult + (hideFee ? agencyFee : 0) + (hideFeeParent ? agencyFeeParent : 0)
        priceFeeColChild = fareOpt.taxChild + fareOpt.surchargeChild + (hideFee ? agencyFee : 0) + (hideFeeParent ? agencyFeeParent : 0)
        priceFeeColInfant = fareOpt.taxInfant + fareOpt.surchargeInfant
      }
      const result = [
        {
          paxType: 'Adult',
          amount: fareOpt.passenger.adult,
          fare: fareOpt.fareAdult, // giá vé gốc từ hãng
          agencyFee: hideFee ? 0 : agencyFee,
          hideFee,
          surcharge: fareOpt.surchargeAdult + (hideFee ? agencyFee : 0) + (hideFeeParent ? agencyFeeParent : 0),
          tax: fareOpt.taxAdult,
          serviceFee: modifiedServiceFee.adultAmount !== null ? modifiedServiceFee.adultAmount : fareOpt.serviceFeeAdult,
          discount: fareOpt.discountAdult,
          promotion: fareOpt.promotionAdult,
          priceFareCol: priceFareColAdult,
          priceDiscountFareCol: getShowPriceAfterDiscount.value ? fareOpt.discountAdult + fareOpt.promotionAdult : fareOpt.discountAdult,
          priceFeeCol: priceFeeColAdult,
          priceTotalCol: priceFareColAdult + priceFeeColAdult,
        },
        {
          paxType: 'Child',
          amount: fareOpt.passenger.child,
          fare: fareOpt.fareChild, // giá vé gốc từ hãng
          agencyFee: hideFee ? 0 : agencyFee,
          hideFee,
          surcharge: fareOpt.surchargeChild + (hideFee ? agencyFee : 0) + (hideFeeParent ? agencyFeeParent : 0),
          tax: fareOpt.taxChild,
          serviceFee: modifiedServiceFee.childAmount !== null ? modifiedServiceFee.childAmount : fareOpt.serviceFeeChild,
          discount: fareOpt.discountChild,
          promotion: fareOpt.promotionChild,
          priceFareCol: priceFareColChild,
          priceDiscountFareCol: getShowPriceAfterDiscount.value ? fareOpt.discountChild + fareOpt.promotionChild : fareOpt.discountChild,
          priceFeeCol: priceFeeColChild,
          priceTotalCol: priceFareColChild + priceFeeColChild,
        },
        {
          paxType: 'Infant',
          amount: fareOpt.passenger.infant,
          fare: fareOpt.fareInfant, // giá vé gốc từ hãng
          agencyFee: 0, // hideFee ? 0 : agencyFee,
          hideFee,
          surcharge: fareOpt.surchargeInfant,
          tax: fareOpt.taxInfant,
          serviceFee: modifiedServiceFee.infantAmount !== null ? modifiedServiceFee.infantAmount : fareOpt.serviceFeeInfant,
          discount: fareOpt.discountInfant,
          promotion: fareOpt.promotionInfant,
          priceFareCol: priceFareColInfant,
          priceDiscountFareCol: getShowPriceAfterDiscount.value ? fareOpt.discountInfant + fareOpt.promotionInfant : fareOpt.discountInfant,
          priceFeeCol: priceFeeColInfant,
          priceTotalCol: priceFareColInfant + priceFeeColInfant,
        },
      ]
      let total = 0
      if (getShowPrice.value === 'TICKET_FARE') {
        total = result.reduce((a, c) => a + (c.fare * c.amount), 0)
      } else {
        total = result.reduce((a, c) => a + (c.priceTotalCol * c.amount), 0)
      }
      return {
        fareOpts: result.filter(item => item.amount),
        total,
      }
    }

    const priceCombinationOption = computed(() => {
      if (!tripItem.value) return null
      let arrayFareOption = []

      if (!props.isChecked) {
        const journeyIds = tripItem.value?.bestCombinationFare?.journeyIds
        journeyIds.forEach(journeyId => {
          const journeyFound = tripItem.value?.journeys.find(j => j.journeyId === journeyId)
          if (journeyFound) {
            arrayFareOption.push(journeyFound.fareOptions)
          }
        })
        return arrayFareOption.length ? resolveDetailPrice(combinateFareOptions(arrayFareOption)) : {}
      }
      arrayFareOption = store.getters['app-flight-v2/getCombinationSelectedFareOptions']
      return arrayFareOption.length ? resolveDetailPrice(combinateFareOptions(arrayFareOption)) : {}
    })

    const resolveBadgeSource = (source, contentSource) => {
      if (['GDS', 'NDC'].includes(contentSource)) return `logo-${source} logo-${contentSource}`
      if (['F1', 'AK', 'TH', 'VN1A'].includes(source)) return `logo-${source}`
      return ''
    }

    return {
      resolveBadgeSource,
      visibleCollapse,
      formatCurrency,
      handleSelectCombinationTrip,
      isVisiblePriceFareCol,
      isVisibleTotalFareCol,
      priceCombinationOption,
    }
  },
}
</script>
<style lang="scss">
.button-remove-focus:focus {
  background: transparent
}
</style>
