var render = function () {
  var _vm$tripItem, _vm$tripItem2, _vm$tripItem2$airline;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-25 rounded-8px",
    staticStyle: {
      "background-color": "#E6E6E6",
      "padding": "2px 4px"
    }
  }, [_c('div', {
    staticClass: "d-flex cursor-pointer w-100",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        $event.preventDefault();
        _vm.visibleCollapse = !_vm.visibleCollapse;
      }
    }
  }, [_c('div', {
    staticClass: "d-flex-between flex-fill"
  }, [_c('div', [_c('div', {
    style: "".concat(['1G', 'F1', 'AK', 'TH', '1A'].includes(_vm.tripItem.source) ? "position: relative; box-sizing: border-box; overflow: hidden; width: 100%;" : '', " ")
  }, [_c('div', {
    class: "d-flex-center ml-1 ml-sm-2 ".concat(_vm.resolveBadgeSource(_vm.tripItem.source, (_vm$tripItem = _vm.tripItem) === null || _vm$tripItem === void 0 ? void 0 : _vm$tripItem.contentSource))
  }, [_c('IAmLogoAirline', {
    attrs: {
      "airline": (_vm$tripItem2 = _vm.tripItem) === null || _vm$tripItem2 === void 0 ? void 0 : (_vm$tripItem2$airline = _vm$tripItem2.airline) === null || _vm$tripItem2$airline === void 0 ? void 0 : _vm$tripItem2$airline.split(' | ')[0],
      "height": 40
    }
  })], 1)])]), _vm.isVisiblePriceFareCol ? _c('div', {
    staticClass: "d-flex-center gap-1"
  }, [_c('span', [_vm._v("Giá vé:")]), _c('span', {
    staticClass: "font-weight-bolder font-medium-3"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.priceCombinationOption.fareOpts[0].priceFareCol)))])]) : _vm._e(), _vm.isVisibleTotalFareCol ? _c('div', {
    staticClass: "d-flex-center gap-1"
  }, [_c('span', [_vm._v("Tổng:")]), _c('span', {
    staticClass: "fw-800 font-medium-4 text-danger"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.priceCombinationOption.fareOpts[0].priceTotalCol)))])]) : _vm._e()]), _c('div', {
    staticClass: "d-flex gap-1 px-1 py-25"
  }, [_c('BButton', {
    staticClass: "rounded-circle button-remove-focus",
    staticStyle: {
      "padding": "1px"
    },
    attrs: {
      "variant": "flat-warning"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.handleSelectCombinationTrip(_vm.tripItem);
      }
    }
  }, [_vm.isChecked ? _c('feather-icon', {
    staticClass: "text-warning p-25",
    attrs: {
      "icon": "CheckCircleIcon",
      "size": ['xs'].includes(_vm.appBreakPoint) ? '22' : '28'
    }
  }) : _c('feather-icon', {
    staticClass: "p-25 text-secondary",
    attrs: {
      "icon": "CircleIcon",
      "size": ['xs'].includes(_vm.appBreakPoint) ? '22' : '28'
    }
  })], 1), _c('BButton', {
    class: "".concat(_vm.visibleCollapse ? null : 'collapsed', " rounded-circle p-50"),
    attrs: {
      "variant": "flat-dark",
      "aria-expanded": _vm.visibleCollapse ? 'true' : 'false'
    }
  }, [_c('feather-icon', {
    class: {
      'rotate-180': !_vm.visibleCollapse,
      'rotate-transition': true
    },
    attrs: {
      "icon": "ChevronUpIcon",
      "size": "16"
    }
  })], 1)], 1)]), _c('BCollapse', {
    attrs: {
      "id": _vm.tripItem.id,
      "visible": _vm.visibleCollapse,
      "role": "tabpanel"
    }
  }, [_c('TableDetailPrice', {
    attrs: {
      "price-list": _vm.priceCombinationOption,
      "source": _vm.tripItem.source
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }